import React from 'react'
import { CCol, CSelect, CRow } from '@coreui/react'
import PropTypes from 'prop-types'

function WalletDropdown({ label, onChange, innerRef, options, selected }) {
  return (
    <CCol xs="12" md="12">
      <CRow>
        <CCol xs="12" md="4">
          <div className="w-full mb-3 mt-4">
            <label
              className="text-xs text-gray-500 mr-4"
              htmlFor="walletDropdown"
            >
              {label}
            </label>
            <CSelect
              id="Wallet"
              name="Wallet"
              innerRef={innerRef()}
              onChange={onChange}
              className="player-c"
              value={selected}
            >
              {options?.map((option) => (
                <option key={option.id} value={option.walletId}>
                  {option.code}
                </option>
              ))}
            </CSelect>
          </div>
        </CCol>
      </CRow>
    </CCol>
  )
}

WalletDropdown.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.any,
}

WalletDropdown.defaultProps = {
  label: '',
  selected: null,
  innerRef: { current: null },
}

export default WalletDropdown
