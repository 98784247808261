import { axiosClient } from '../clients'

export async function saveLifetimeDepositLimit(data) {
  try {
    const {
      data: { result },
    } = await axiosClient.post(`/lifetime-deposit-limit`, data)
    return result
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getLifetimeDepositLimit(currencyCode) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/lifetime-deposit-limit?currency=${currencyCode}`)
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function saveAffordabilityDepositLimit(data) {
  try {
    const {
      data: { result },
    } = await axiosClient.post(`/affordability-override`, data)
    return result
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getAffordabilityDepositLimit(playerId, currencyCode) {
  try {
    const {
      data: { result },
    } = await axiosClient(
      `/affordability-limit?playerID=${playerId}&currency=${currencyCode}`,
    )
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
