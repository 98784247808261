import {
  getTotalPlayers,
  getActivePlayers,
  getActiveTrends,
  getTotalTrends,
  getTotalByCountry,
  getTopByCountry,
  getTotalContinent,
  getTotalNewPlayers,
  getTotalNewContinent,
  getPlayersOnline,
  getNewTrends,
  getPlayersStatusById,
  getPlayersStatus,
  getPlayerOnline,
  getAnalytics,
  getPlayersSession,
  getPlayersChangelog,
  getPlayerChangelog,
  getPlayerVerificationData,
  getPlayers,
  getPlayer,
  updatePlayer,
  updatePartialPlayer,
  addNoteForPlayer,
  getNotesPlayer,
  getNotePlayer,
  deleteNote,
  editNote,
  patchNote,
  getSelfExclusionInformation,
  getTimeOutInformation,
  UpdatePlayerStatus,
  UpdatePlayerStatusDuration,
  UpdateSelfExclusionDuration,
  getStats,
  deleteAttachment,
  addAttachment,
  getKYCdetails,
  retryKYCcheck,
  getAccountSummary,
  getPlayerTags,
  getTags,
  updatePlayerTags,
  deletePlayerTags,
} from '@services/queries'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  STATUS_SUCCESS_MESSAGE,
  STATUS_FAILURE_MESSAGE,
  DETAILS_FAILURE_MESSAGE,
  DETAILS_SUCCESS_MESSAGE,
  NOTES_FAILURE_MESSAGE,
  NOTES_SUCCESS_MESSAGE,
  NOTES_DELETE_FAILURE_MESSAGE,
  NOTES_DELETE_SUCCESS_MESSAGE,
  NOTES_EDIT_FAILURE_MESSAGE,
  NOTES_EDIT_SUCCESS_MESSAGE,
  ATTACHMENTS_EDIT_FAILURE_MESSAGE,
  ATTACHMENTS_EDIT_SUCCESS_MESSAGE,
  E_KYC_SUCCESS,
  E_KYC_FAIL,
} from '@constants'
import { store } from 'react-notifications-component'

export function usePlayer(playerId) {
  const info = useQuery(['player', playerId], () => getPlayer(playerId))

  return info
}

export function usePlayers(searchText = '', page = '', limit = '') {
  const info = useQuery(['players', searchText, page, limit], () =>
    getPlayers(searchText, page, limit),
  )

  return info
}

export function usePlayerChangelog(playerId, enabled = true) {
  const info = useQuery(
    ['players_changelog', playerId],
    () => getPlayerChangelog(playerId),
    {
      enabled,
    },
  )

  return info
}

export function usePlayerVerificationData(playerId, enabled = true) {
  const info = useQuery(
    ['players_verification_data', playerId],
    () => getPlayerVerificationData(playerId),
    {
      enabled,
    },
  )

  return info
}

export function usePlayersChangelog(start, end, enabled = true) {
  const info = useQuery(
    ['players/changelog'],
    () => getPlayersChangelog(start, end),
    {
      enabled,
    },
  )

  return info
}

export function useStatusDurationMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (statusData) => {
      return UpdatePlayerStatusDuration(statusData)
    },
    {
      onSuccess(data, statusData) {
        queryClient.invalidateQueries(['player_status_duration'])
        queryClient.invalidateQueries([
          'status_by_id',
          statusData.statusObj.playerId,
        ])
        queryClient.invalidateQueries([
          'status_by_id_time_out',
          statusData.statusObj.playerId,
        ])
        if (onSuccess) {
          onSuccess(statusData.status)
        }

        if (data) store.addNotification(STATUS_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('Error updating player status', err)
        store.addNotification(STATUS_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function useSelfExclusionDurationMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (statusData) => {
      return UpdateSelfExclusionDuration(statusData.statusObj)
    },
    {
      onSuccess(data, statusData) {
        queryClient.invalidateQueries(['player_status_duration'])
        queryClient.invalidateQueries([
          'status_by_id',
          statusData.statusObj.playerId,
        ])
        if (onSuccess) {
          onSuccess(statusData.status)
        }

        if (data) store.addNotification(STATUS_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('Error updating player status', err)
        store.addNotification(STATUS_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function usePlayersSession(date, players) {
  const info = useQuery(['session-duration', date, players], () =>
    getPlayersSession(date, players),
  )

  return info
}

export function usePlayerAnalytics(id) {
  const info = useQuery(['analytics_player', id], () => getAnalytics(id))

  return info
}

export function usePlayersStatusMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (statusData) => {
      return UpdatePlayerStatus(statusData)
    },
    {
      onSuccess(data, statusData) {
        queryClient.invalidateQueries(['player_status'])
        queryClient.invalidateQueries(['player'])
        queryClient.invalidateQueries([
          'status_by_id',
          statusData.statusObj.playerId,
        ])
        queryClient.invalidateQueries([
          'status_by_id_time_out',
          statusData.statusObj.playerId,
        ])
        if (onSuccess) {
          onSuccess(statusData.status)
        }
        if (data) store.addNotification(STATUS_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('Error updating player status', err)
        store.addNotification(STATUS_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export const usePlayerOnline = (playerId) => {
  const info = useQuery(['player_online', playerId], () =>
    getPlayerOnline(playerId),
  )

  return info
}

export function usePlayersStatus() {
  const info = useQuery(['player_status'], () => getPlayersStatus())

  return info
}

export function usePlayersStatusId(statusId) {
  const info = useQuery(['player_status_id', statusId], () =>
    getPlayersStatusById(statusId),
  )

  return info
}

export const useTotalPlayers = () => useQuery(['totalPlayers'], getTotalPlayers)

export const useTotalNewPlayers = () => {
  const info = useQuery(['totalNewPlayers'], getTotalNewPlayers)

  return info
}

export const useActivePlayers = () => {
  const info = useQuery(['activePlayers'], getActivePlayers)

  return info
}

export const useActiveTrends = () => {
  const info = useQuery(['activeTrends'], getActiveTrends)

  return info
}

export const useNewTrends = () => {
  const info = useQuery(['newTrends'], getNewTrends)

  return info
}

export const useTotalTrends = () => {
  const info = useQuery(['totalTrends'], getTotalTrends)

  return info
}

export const useTotalByCountry = () => {
  const info = useQuery(['totalByCountry'], getTotalByCountry)

  return info
}

export const useTopByCountry = () => {
  const info = useQuery(['topByCountry'], getTopByCountry)

  return info
}

export const useTotalContinent = () => {
  const info = useQuery(['totalContinent'], getTotalContinent)

  return info
}

export const useTotalNewContinent = () => {
  const info = useQuery(['totalNewContinent'], getTotalNewContinent)

  return info
}

export const useStats = (params) => {
  const info = useQuery(['stats'], () => getStats(params))

  return info
}

export const usePlayersOnline = () => {
  const info = useQuery(['players_online'], getPlayersOnline)

  return info
}

export function useUpdatePlayerMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    (player) => {
      return updatePlayer(player.playerId, { ...player.player })
    },
    {
      onSuccess(data, player) {
        player.handleDisPatchPlayer()
        queryClient.invalidateQueries(['players'])
        queryClient.invalidateQueries(['player', player.playerId])
        queryClient.invalidateQueries(['players_changelog', player.playerId])
        if (onSuccess) onSuccess(data)
        if (data) store.addNotification(DETAILS_SUCCESS_MESSAGE)
      },
      onError(err, player) {
        player.handleCancel()
        console.error('ERROR UPDATING PLAYER', err)
        if (onError) onError(err)
        store.addNotification(DETAILS_FAILURE_MESSAGE)
      },
    },
  )

  return mutationInfo
}

export function useUpdatePartialPlayerMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    (player) => {
      return updatePartialPlayer(player.playerId, { ...player.player })
    },
    {
      onSuccess(data, player) {
        player.handleDisPatchPlayer()
        queryClient.invalidateQueries(['players'])
        queryClient.invalidateQueries(['player', player.playerId])
        queryClient.invalidateQueries(['players_changelog', player.playerId])
        if (onSuccess) onSuccess(data)
        if (data) store.addNotification(DETAILS_SUCCESS_MESSAGE)
      },
      onError(err, player) {
        player.handleCancel()
        console.error('ERROR UPDATING PLAYER', err)
        if (onError) onError(err)
        store.addNotification(DETAILS_FAILURE_MESSAGE)
      },
    },
  )

  return mutationInfo
}

export function useNewNotePlayerMutation(onSuccess, onError) {
  const mutationInfo = useMutation(addNoteForPlayer, {
    onSuccess(data) {
      if (onSuccess) onSuccess(data)
      if (data) store.addNotification(NOTES_SUCCESS_MESSAGE)
    },
    onError(err) {
      store.addNotification(NOTES_FAILURE_MESSAGE)
      if (onError) onError(err)
    },
  })
  return mutationInfo
}

export const useNotesPlayer = (playerId, notesFilters) => {
  const { search, startDate, endDate } = notesFilters
  const info = useQuery(
    ['notes_player', playerId, search, startDate, endDate],
    () => getNotesPlayer(playerId, notesFilters),
  )
  return info
}

export const useNotePlayer = (nodeId) => {
  const info = useQuery(['note_player', nodeId], () => getNotePlayer(nodeId))

  return info
}

export function useDeleteNoteMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (noteData) => {
      return deleteNote(noteData.id)
    },
    {
      onSuccess(data, noteData) {
        if (onSuccess) onSuccess(data)
        queryClient.invalidateQueries(['notes_player', noteData.id])
        store.addNotification(NOTES_DELETE_SUCCESS_MESSAGE)
      },
      onError(err) {
        store.addNotification(NOTES_DELETE_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )
  return mutationInfo
}

export function useEditNoteMutation(onSuccess, onError) {
  const mutationInfo = useMutation(
    ([id, note]) => {
      return editNote(id, note)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) onSuccess(data)
        store.addNotification(NOTES_EDIT_SUCCESS_MESSAGE)
      },
      onError: (error) => {
        if (onError) store.addNotification(NOTES_EDIT_FAILURE_MESSAGE)
        onError(error)
      },
    },
  )

  return mutationInfo
}

export function usePatchNoteMutation(onSuccess, onError) {
  const mutationInfo = useMutation(
    ([id, note]) => {
      return patchNote(id, note)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) onSuccess(data)
      },
      onError: (error) => {
        onError(error)
      },
    },
  )

  return mutationInfo
}

export function useSelfExclusionInformation(playerId) {
  const info = useQuery(['status_by_id', playerId], () =>
    getSelfExclusionInformation(playerId),
  )

  return info
}

export function useTimeOutInformation(playerId) {
  const info = useQuery(['status_by_id_time_out', playerId], () =>
    getTimeOutInformation(playerId),
  )

  return info
}

export function useDeleteAttachmentMutation(onSuccess, onError) {
  const mutationInfo = useMutation(
    (id) => {
      return deleteAttachment(id)
    },
    {
      onError(err) {
        store.addNotification(ATTACHMENTS_EDIT_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )
  return mutationInfo
}

export function useAddAttachmentMutation(onSuccess, onError) {
  const mutationInfo = useMutation(([id, file]) => addAttachment([id, file]), {
    onSuccess(data) {
      store.addNotification(ATTACHMENTS_EDIT_SUCCESS_MESSAGE)
      onSuccess(data)
    },
    onError(err) {
      store.addNotification(ATTACHMENTS_EDIT_FAILURE_MESSAGE)
      if (onError) onError(err)
    },
  })
  return mutationInfo
}

export function useKYCdetails(playerId, enabled) {
  const info = useQuery(
    ['kyc_failure_details', playerId],
    () => getKYCdetails(playerId),
    {
      enabled,
    },
  )

  return info
}

export function useKYCcheck(onSuccess, onError) {
  const queryClient = useQueryClient()
  const info = useMutation((playerId) => retryKYCcheck(playerId), {
    onSuccess(data) {
      if (data?.result === 'Fail')
        store.addNotification(E_KYC_FAIL(data?.reason))
      else {
        queryClient.invalidateQueries(['player_status_duration'])
        queryClient.invalidateQueries(['status_by_id', data?.player_id])
        queryClient.invalidateQueries([
          'status_by_id_time_out',
          data?.player_id,
        ])
        store.addNotification(E_KYC_SUCCESS)
      }

      if (onSuccess) {
        onSuccess(data)
      }
    },
    onError(err) {
      if (onError) onError(err)
    },
  })

  return info
}

export const useAccountSummary = (summaryFilters) => {
  const { start, end, playerID, currency } = summaryFilters
  const info = useQuery(
    ['summary_player', playerID, currency, start, end],
    () => getAccountSummary(summaryFilters),
  )
  return info
}

export function usePlayerTags(playerId, enabled = false) {
  const info = useQuery(
    ['player_tags', playerId],
    () => getPlayerTags(playerId),
    {
      enabled,
    },
  )

  return info
}

export function useTags(playerId) {
  const info = useQuery(['tags'], () => getTags(playerId))

  return info
}

export function useTagsMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const info = useMutation(
    ([playerId, data]) => updatePlayerTags(playerId, data),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['player_tags'])
        if (onSuccess) onSuccess(data)
      },
      onError(err) {
        if (onError) onError(err)
      },
    },
  )

  return info
}

export function useDeleteTagsMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const info = useMutation(
    ([playerId, data]) => deletePlayerTags(playerId, data),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['player_tags'])
        if (onSuccess) onSuccess(data)
      },
      onError(err) {
        if (onError) onError(err)
      },
    },
  )

  return info
}
