/**
 * @brief Verifies if any limit increased.
 *
 * @param formerLimit Former limits object.
 * @param currentLimit New limits object.
 * @returns Boolean specifying the increasing condition
 */
export function verifyIncrease(formerLimit, currentLimit) {
  if (formerLimit.daily < currentLimit.daily) return true

  if (formerLimit.weekly < currentLimit.weekly) return true

  if (formerLimit.monthly < currentLimit.monthly) return true

  return false
}
