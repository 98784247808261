import format from 'date-fns/format'

/**
 * @brief Formats a date string into a human-readable format with the day, month, and year.
 *
 * @param dateString The date string to format.
 * @returns The formatted date string.
 */
export function formatDateProfile(dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const dateFormatted = format(date, 'd MMMM yyyy')

    return dateFormatted
  }
  return ''
}

/**
 * @brief Formats a date string into a human-readable format according to UK standards, including the time zone.
 *
 * @param dateString The date string to format.
 * @returns The formatted date string in UK format.
 */
export function formatDateUK(dateString) {
  const date = new Date(dateString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  }
  const ukTime = date.toLocaleDateString('en-GB', options)

  return ukTime?.slice(0, 28).replace('at ', '')
}

/**
 * @brief Sanitizes a datetime string by removing the letter T.
 *
 * @param datetimeString The datetime string to sanitize.
 * @returns The sanitized datetime string.
 */
export function sanitizeDatetime(datetimeString) {
  if (datetimeString) {
    return datetimeString.replace('T', ' ')
  }
  return ''
}
