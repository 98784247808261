import { axiosClient } from '@services/clients'

export async function getCampaigns() {
  const {
    data: { campaigns },
  } = await axiosClient('/campaigns?page_size=100')

  return campaigns
}

export async function getCampaign(campaignId) {
  const { data } = await axiosClient(`/campaign/${campaignId}`)

  return data
}

export async function getCampaignEnabled(data, campaignId) {
  return axiosClient.patch(`/campaign/${campaignId}`, data)
}

export async function newCampaign(dataC) {
  const {
    data: { result },
  } = await axiosClient.post('/campaign', dataC)

  return result
}
