import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBalance,
  setActualStatus,
} from '@redux/actions/playerDetailsActions'
import { getSessionUser } from '@redux/selectors'

const WebSocketContext = createContext(null)

export { WebSocketContext }

const TYPE_BALANCE_UPDATE = 'player/balance-update'
const TYPE_STATUS_UPDATE = 'player/status-update'

function Socket({ children }) {
  let socket
  let ws

  const dispatch = useDispatch()
  const usr = useSelector(getSessionUser)

  if (usr === null) {
    ws = {}

    return (
      <WebSocketContext.Provider value={ws}>
        {children}
      </WebSocketContext.Provider>
    )
  }

  const { id } = usr

  if (!socket) {
    const baseUrl = window._env_.REACT_APP_SERVER_URL.replace(/^http/, 'ws')
      .replace(/\/*$/, '')
      .replace(/\./, '.ws.')
      .replace('/api/v1', '')
      .replace('.api', '')
      .replace('.backoffice', '')
    socket = new WebSocket(`${baseUrl}/ws?id=${id}`)
    socket.addEventListener('message', (e) => {
      const payload = JSON.parse(e.data)
      if (payload.type === TYPE_BALANCE_UPDATE) {
        dispatch(updateBalance(payload))
      }

      if (payload.type === TYPE_STATUS_UPDATE) {
        dispatch(setActualStatus(payload.payload))
      }
    })

    ws = {
      socket,
    }
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  )
}

Socket.propTypes = {
  children: PropTypes.element,
}

Socket.defaultProps = {
  children: null,
}

export default Socket
