import { axiosClient } from '../clients'

export async function getWagersContributionToCashBonus() {
  try {
    const {
      data: { result },
    } = await axiosClient('/wager-contribution')
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function updateWagersContributionToCashBonus(gameType, data) {
  try {
    const {
      data: { result },
    } = await axiosClient.patch(`/wager-contribution/${gameType}`, data)
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
