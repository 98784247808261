import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import Card from '@components/molecules/Cards/Card'

function FinancialDropdown({ label, onChange, options, selected }) {
  // --- Hooks -----------------------------------------------------------------
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  // --- END: Redux ------------------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  // --- END: Side effects -----------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const title = useMemo(() => {
    return options?.find((option) => option?.value === selected)?.label
  }, [options, selected])
  // --- END: Data and handlers ------------------------------------------------
  return (
    <Card title={label}>
      <CDropdown selected="Statistics" className="c-dropdown-button">
        <CDropdownToggle className="player-c" color="secondary">
          {title}
        </CDropdownToggle>
        <CDropdownMenu className="player-c dropdown-generic">
          {options?.map((option, index) => (
            <CDropdownItem
              className="c-player-card-text"
              key={`dropdown-item-${index + 1}`}
              onClick={() => onChange(option?.value)}
            >
              {option?.label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </Card>
  )
}

FinancialDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.any.isRequired,
}

export default FinancialDropdown
