import {
  getWagersContributionToCashBonus,
  updateWagersContributionToCashBonus,
} from '@services/queries'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { store } from 'react-notifications-component'
import { TRANSACTION_FAILURE, CUSTOM_SUCCESS_NOTIFICATION } from '@constants'

export function useWagersContributionToCashBonus() {
  const info = useQuery(['wagers_contribution_to_cash_bonus'], () =>
    getWagersContributionToCashBonus(),
  )

  return info
}

export function useWagersContributionToCashBonusMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    ([gameType, data]) => updateWagersContributionToCashBonus(gameType, data),
    {
      onSuccess(data) {
        if (onSuccess) onSuccess(data)
        queryClient.invalidateQueries(['wagers_contribution_to_cash_bonus'])
        if (data)
          store.addNotification(
            CUSTOM_SUCCESS_NOTIFICATION('Value updated successfully.'),
          )
      },
      onError(err) {
        store.addNotification(
          TRANSACTION_FAILURE(
            'Something went wrong updating the value, please try again.',
          ),
        )
        if (onError) onError(err)
      },
    },
  )
  return mutationInfo
}
