/* eslint-disable camelcase */
/**
 * @brief Calculates the age of a user based on their date of birth.
 *
 * @param dateString The date of birth in string format.
 * @returns The user's age.
 */
export function getAge(dateString) {
  const today = new Date()
  const bornDate = new Date(dateString)
  let age = today.getFullYear() - bornDate.getFullYear()
  const monthDIffer = today.getMonth() - bornDate.getMonth()
  if (
    monthDIffer < 0 ||
    (monthDIffer === 0 && today.getDate() < bornDate.getDate())
  )
    // eslint-disable-next-line no-plusplus
    age--

  return age
}

/**
 * @brief Retrieves the status name for a given player status ID.
 *
 * @param value The player status ID.
 * @param rawPlayersStatus An array of player status objects.
 * @returns The corresponding status name.
 */
export function nameStatus(value, rawPlayersStatus) {
  const statusNames = {
    provisional: 'provisional',
    frozen: 'frozen',
    banned: 'banned',
    active: 'active',
    'self-excluded': 'self-excluded',
    closed: 'closed',
    'international-kyc': 'international-kyc',
    'customer-closure': 'customer-closure',
    'dormant-account': 'dormant-account',
    'under-investigation': 'under-investigation',
    fraud: 'fraud',
    'duplicate-account': 'duplicate-account',
    'site-closed': 'site-closed',
    'time-out': 'time-out',
    'previously-excluded': 'previously-excluded',
    'national-register-excluded': 'national-register-excluded',
    'previous-gamstop': 'previous-gamstop',
    'active-bonus-restricted': 'active-bonus-restricted',
    'social-responsibility-risk': 'social-responsibility-risk',
    suspended: 'suspended',
    'kyc-fail': 'kyc-fail',
    locked: 'locked',
    risk: 'risk',
  }

  const status = rawPlayersStatus?.find((item) => item.id === value)
  return statusNames[status?.name] ?? ''
}
/**
 * @brief Determines the current status display for a player based on their status ID and any recent status changes.
 *
 * @param statusChange The recent status change for the player.
 * @param status The player's current status object.
 * @returns The appropriate status display text.
 */
export function statusName(statusChange, status) {
  return statusChange !== ''
    ? statusChange
    : nameStatus(status.status_id, status.rawPlayersStatus) || ''
}
/**
 * @brief Creates a player data object by combining information from various player-related sources.
 *
 * @param rawPlayer The raw player data object.
 * @param rawPlayerAnalytics The raw player analytics data object.
 * @param isPlayerOnline A boolean indicating whether the player is currently online.
 * @param rawPlayersStatus An array of player status objects.
 * @param statusChange The current status change for the player.
 * @param kycFailData If the current status is kyc-fail this will contain the details about it
 * @returns The combined player data object.
 */
export function getPlayerData(
  rawPlayer,
  rawPlayerAnalytics,
  isPlayerOnline,
  rawPlayersStatus,
  statusChange,
  kycFailData,
  playerTags,
) {
  const { date_of_birth, address_1, address_2, country, status_id } = rawPlayer
  const status = { status_id, rawPlayersStatus }
  return {
    ...rawPlayer,
    ...rawPlayerAnalytics,
    online: isPlayerOnline?.online || '',
    date_of_birth: date_of_birth || '',
    address1: address_1,
    address2: address_2 || '',
    country: country || '',
    status: statusName(statusChange, status),
    statusId: status_id,
    kycFailData: kycFailData || '',
    tagsData: playerTags || [],
  }
}
/**
 * @brief Determines the display of the url according to the type and id of the player.
 *
 * @param type The recent status change for the player.
 * @param playerData The player current object.
 * @returns Returns a url redirect according to the type of option selected.
 */
export function optionsWrapper(type, id, history) {
  const OPTION_COMPONENTS = {
    STATISTICS: `/players/depositGraph/${id}`,
    PLAYER_DETAILS: `/players/details/${id}`,
    MARKETING: `/players/marketing/${id}`,
    WITHDRAWAL_DEPOSIT_LIMITS: `/players/limits/${id}`,
    WALLETS_BONUSES: `/players/wallets/${id}`,
    GRAPH: `/players/graphs/${id}`,
    TRANSACTION_HISTORY: `/players/transaction/${id}`,
    BONUSES_ASSIGNMENTS: `/players/bonuses/${id}`,
    PLAYER_NOTES: `/players/notes/${id}`,
    PLAYER_TAGS: `/players/tags/${id}`,
    AUDIT_HISTORY: `/players/audit/${id}`,
    VERIFICATION_DATA: `/players/verification-data/${id}`,
  }
  return history.push({
    pathname: OPTION_COMPONENTS[type],
  })
}
