import { axiosClient } from '@services/clients'

export async function getPlayerBalances(playerId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/balances/player/${playerId}`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayerOverrideStatus(playerId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/${playerId}/payment-method-override`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function updatePlayerOverrideStatus(playerId, body) {
  try {
    const {
      data: { result },
    } = await axiosClient.post(
      `/players/${playerId}/payment-method-override`,
      body,
    )

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
