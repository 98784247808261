import { camelizeObjectKeys } from '@utils'
import { axiosClient } from '@services/clients'
import { countries } from '@shared'

export async function getTotalPlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalNewPlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total?days=30`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getActivePlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/active`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getActiveTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/active/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getNewTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/new/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalByCountry() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/country`)
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTopByCountry() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/info/country`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalContinent() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/continent`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalNewContinent() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/continent?days=30`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayersOnline() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/online`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayer(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/${playerId}`)

  return result
}

export async function getPlayers(searchText, page, limit) {
  const { data } = await axiosClient(
    `/players?search=${searchText}&page=${page}&limit=${limit}`,
  )

  return data
}

export async function getPlayersStatus() {
  const {
    data: { result },
  } = await axiosClient('/players/status')

  return result
}

export async function getPlayerOnline(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/${playerId}/online`)
  return result
}

export async function getPlayersStatusById(statusId) {
  const {
    data: { result },
  } = await axiosClient(`players/status/${statusId}`)
  return result
}

export async function getSelfExclusionInformation(playerId) {
  const {
    data: { result },
  } = await axiosClient(`players/${playerId}/status/self-exclusion`)
  return result
}

export async function getTimeOutInformation(playerId) {
  const {
    data: { result },
  } = await axiosClient(`players/${playerId}/status/timeout`)
  return result
}

export async function UpdatePlayerStatus({ statusObj, status }) {
  const data = {
    player_status_id: statusObj.player_status_id,
    reason: statusObj.reason,
  }
  const {
    data: { result },
  } = await axiosClient.put(
    `/players/${statusObj.playerId}/${status}/status`,
    data,
  )
  return {
    ...result,
    data,
  }
}

export async function UpdatePlayerStatusDuration(data) {
  const { statusObj, status } = data

  const dataR = {
    player_status_id: statusObj.player_status_id,
    reason: statusObj.reason,
    date: statusObj.date,
  }
  const {
    data: { result },
  } = await axiosClient.put(
    `/players/${statusObj.playerId}/${status.status}/status`,
    dataR,
  )
  return {
    ...result,
    data,
  }
}

export async function UpdateSelfExclusionDuration(data) {
  const dataR = {
    status_id: data.player_status_id,
    end_date: data.date,
  }
  const {
    data: { result },
  } = await axiosClient.put(
    `/players/${data.playerId}/self-exclusion-period`,
    dataR,
  )
  return {
    ...result,
    data,
  }
}

export async function getPlayerChangelog(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/changelog/${playerId}`)
  return result
}

export async function getPlayersChangelog(start, end) {
  const {
    data: { result },
  } = await axiosClient('/players/changelog', start, end)

  return result
}

export async function getPlayerVerificationData(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/transunion/logs?playerID=${playerId}`)
  return result
}

export async function updatePlayer(playerId, data) {
  // contact_number and dial_code formatting
  const dialCode =
    countries.find((c) => c.value === data?.country)?.dial_code ?? ''
  const phone = data?.contact_number?.split?.(dialCode)
  const contactNumber = phone[1]
  const formatedData = {
    ...data,
    dial_code: dialCode,
    contact_number: contactNumber,
  }

  const {
    data: { result },
  } = await axiosClient.put(`/players/${playerId}`, formatedData)

  return result
}

export async function updatePartialPlayer(playerId, data) {
  const {
    data: { result },
  } = await axiosClient.patch(`/players/${playerId}`, data)

  return result
}

export async function getPlayersSession(date, players) {
  const {
    data: { result },
  } = await axiosClient(
    `/players/top/session-duration?limit=${players}&days=${date}`,
  )
  return result
}

export async function addNoteForPlayer(data) {
  const form = new FormData()
  data.attachments.forEach((file) => form.append('attachments', file))

  Object.keys(data).forEach((key) => {
    if (key !== 'attachments' && key !== 'newIdNote') {
      form.append(key, data[key])
    }
  })
  const {
    data: { result },
  } = await axiosClient.post(`/players/${data.id}/notes`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return Promise.resolve(result)
}

export async function getNotesPlayer(playerId, notesFilters) {
  try {
    const {
      data: { result },
    } = await axiosClient.get(`/players/${playerId}/notes`, {
      params: notesFilters,
    })

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getNotePlayer(noteId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/notes/${noteId}`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function deleteNote(id) {
  const { data } = await axiosClient.delete(`/players/notes/${id}`)
  return data
}

export async function editNote(id, data) {
  const {
    data: { result },
  } = await axiosClient.put(`/players/notes/${id}`, data)
  return Promise.resolve(result)
}

export async function patchNote(id, data) {
  const {
    data: { result },
  } = await axiosClient.patch(`/players/notes/${id}`, data)
  return Promise.resolve(result)
}

export async function getAnalytics(id) {
  const {
    data: { result },
  } = await axiosClient.get(`/players/${id}/analytics`)

  return result
}

export async function deleteAttachment(id) {
  const {
    data: { result },
  } = await axiosClient.delete(`/players/notes/attachment/${id}`)

  return result
}

export async function addAttachment([id, file]) {
  const form = new FormData()
  form.append('attachments', file)

  const {
    data: { result },
  } = await axiosClient.post(`/players/notes/${id}/attachment`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  return result
}

export async function getKYCdetails(id) {
  const {
    data: { result },
  } = await axiosClient.get(`/players/${id}/kyc-failure-details`)

  return result
}

export async function retryKYCcheck(id) {
  const {
    data: { result },
  } = await axiosClient.post(`/players/${id}/kyc-check`)

  return result
}

export async function getAccountSummary(summaryFilters) {
  try {
    const {
      data: { result },
    } = await axiosClient.get(`/account-summaries`, {
      params: summaryFilters,
      headers: { 'authorized-party': 'backoffice' },
    })

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayerTags(playerId) {
  const { data } = await axiosClient(`/players/${playerId}/tags`)

  return data
}

export async function getTags() {
  const { data } = await axiosClient(`/players/tags`)

  return data
}

export async function updatePlayerTags(playerId, body) {
  const { data } = await axiosClient.post(`/players/${playerId}/tags`, body)

  return data
}

export async function deletePlayerTags(playerId, body) {
  const { data } = await axiosClient.delete(`/players/${playerId}/tags`, {
    data: body,
  })

  return data
}
