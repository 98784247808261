export const BONUS = 'bonus'
export const DEPOSIT = 'deposit'
export const PAYMENT = 'payment'
export const CREDIT = 'credit'
export const ACTIVE = 'active'
export const INATIVE = 'inactive'
export const PENDING = 'pending'
export const BANNED = 'banned'
export const FAILED = 'failed'

export const getBadge = (status) => {
  switch (status?.toLowerCase()) {
    case BONUS:
      return 'success'
    case DEPOSIT:
      return 'secondary'
    case PAYMENT:
      return 'info'
    case CREDIT:
      return 'warning'
    case ACTIVE:
      return 'success'
    case INATIVE:
      return 'secondary'
    case PENDING:
      return 'warning'
    case BANNED:
      return 'danger'
    case FAILED:
      return 'danger'
    default:
      return 'success'
  }
}
