import React from 'react'
import { CDropdown, CDropdownItem, CDropdownMenu } from '@coreui/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

function NestedMenu({
  handleNestedRoute,
  item,
  routesHidden,
  selected,
  index,
}) {
  // --- Data and handlers -----------------------------------------------------
  // Note: Not saved in a memo because it interferes with its functionality
  const isNestedSelected =
    item?.nested?.length > 1 && !routesHidden[index].hidden && !selected
  // --- END: Data and handlers ------------------------------------------------

  return (
    <>
      <CDropdownItem
        onClick={() => handleNestedRoute(index, 0)}
        className={classNames({
          'remove-arrow': item?.nested.length === 1,
          'down-arrow': routesHidden[index].hidden,
        })}
      >
        {item.optionName}
      </CDropdownItem>
      {isNestedSelected && (
        <CDropdown className="c-dropdown-button">
          <CDropdownMenu className="nested-menu">
            {item?.nested?.map((nestedRoute, idx) => (
              <CDropdownItem
                onClick={() => handleNestedRoute(index, idx)}
                key={`nested-menu-cdropdownitem-${idx + 1}`}
              >
                {nestedRoute.optionName}
              </CDropdownItem>
            ))}
          </CDropdownMenu>
        </CDropdown>
      )}
    </>
  )
}

NestedMenu.propTypes = {
  handleNestedRoute: PropTypes.func.isRequired,
  item: PropTypes.shape({
    optionName: PropTypes.string.isRequired,
    nested: PropTypes.arrayOf(
      PropTypes.shape({
        optionName: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  routesHidden: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
}

export default NestedMenu
