import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import React from 'react'
import Card from '@components/molecules/Cards/Card'
import PropTypes from 'prop-types'

function Dropdown({ title, selected, onSelect: handleSelect, options }) {
  return (
    <Card title={title}>
      <CDropdown selected={selected} className="c-dropdown-button">
        <CDropdownToggle color="secondary">{selected}</CDropdownToggle>
        <CDropdownMenu>
          {options.map((label, key) => (
            <CDropdownItem
              component="button"
              key={`dropdown-${key + 1}`}
              className="c-player-card-text"
              onClick={() => handleSelect(label)}
            >
              {label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </Card>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  selected: PropTypes.any,
  onSelect: PropTypes.func,
  options: PropTypes.array,
}

Dropdown.defaultProps = {
  title: '',
  selected: '',
  onSelect: () => null,
  options: [],
}

export default Dropdown
