import { currenciesValues, numberWithCommas } from '@shared'

// Generic error message
export const GENERIC_ERROR_NOTIFICATION = {
  title: 'Alert',
  message: 'An error has ocurred',
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Bonuses messages
export const BONUS_CREATION_SUCCESS_MESSAGE = {
  title: 'Message',
  message: `Successful creation of bonus.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const BONUS_CREATION_FAILURE_MESSAGE = {
  title: 'Message',
  message: `Failed creation of bonus, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const BONUS_EDIT_SUCCESS_MESSAGE = {
  title: 'Message',
  message: `Bonus edited successfully`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const BONUS_EDIT_FAILURE_MESSAGE = {
  title: 'Message',
  message: `Bonus edit failed, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Manual adjusment messages
export function TRANSACTION_SUCCESS_AMOUNT(currency, amount) {
  return {
    title: 'Message',
    message: `Successful  Manual Adjustment in Cash balance of ${numberWithCommas(currenciesValues, amount, currency)}`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function TRANSACTION_SUCCESS_BONUS(currency, bonus) {
  return {
    title: 'Message',
    message: `Successful  Manual Adjustment in Bonus of  ${numberWithCommas(currenciesValues, bonus, currency)}`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function TRANSACTION_FAILURE(error) {
  return {
    title: 'Message',
    message: error,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function CUSTOM_SUCCESS_NOTIFICATION(data) {
  return {
    title: 'Message',
    message: data,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

// Manual adjustment invalid status message
export const INVALID_STATUS = {
  title: 'Message',
  message: "The player's current status is not allowed to make this operation.",
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Manual adjustment user not allowed message
export const INVALID_ROLE = {
  title: 'Message',
  message: 'The operator is not allowed to make this operation.',
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Campaigns messages
export const CAMPAIGN_CREATION_SUCCESS_MESSAGE = {
  title: 'Message',
  message: `Successful creation of campaign.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const CAMPAIGN_CREATION_FAILURE_MESSAGE = {
  title: 'Message',
  message: `Failed creation of campaign, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const CAMPAIGN_ENABLE_SUCCESS_MESSAGE = {
  title: 'Message',
  message: `Successful update of campaign.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const CAMPAIGN_ENABLE_FAILURE_MESSAGE = {
  title: 'Message',
  message: `Failed update of campaign, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Limits messages
// Delete limits
export function DELETE_LIMIT_MESSAGE(type) {
  return {
    title: 'Success!',
    message: `Your ${type} limits have been successfully deleted. This deletion will take effect in 24 hours.`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function DELETE_LIMIT_MESSAGE_FAILURE(type) {
  return {
    title: 'Message!',
    message: `Your ${type} limits have been not successfully deleted, please try again or contact support.`,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

// Create limits
export function CREATE_LIMIT_MESSAGE(type) {
  return {
    title: 'Success!',
    message: `Your ${type} limits have been successfully created.`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function CREATE_LIMIT_MESSAGE_FAILURE(type) {
  return {
    title: 'Message!',
    message: `Your ${type} limits have been not successfully created, please try again or contact support.`,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}
// Update limits
export function UPDATE_LIMIT_MESSAGE(type, limitIncreased) {
  return {
    title: 'Success!',
    message: limitIncreased
      ? `Your ${type} limits have been successfully updated. The increase will be reflected in 24 hours.`
      : `Your ${type} limits have been successfully updated.`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export function UPDATE_LIMIT_MESSAGE_FAILURE(type) {
  return {
    title: 'Message!',
    message: `Your ${type} limits have been not successfully updated, please try again or contact support.`,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

// Player details update messages
export const DETAILS_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to change player details, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const DETAILS_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Player details change has been successfully saved.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Notes messages
export const NOTES_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to save your changes, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const NOTES_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Your note has been successfully saved.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const NOTES_DELETE_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to delete note, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const NOTES_EDIT_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Your note has been successfully edited.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const NOTES_EDIT_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to edited note, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const ATTACHMENTS_EDIT_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to edit attachment, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const ATTACHMENTS_EDIT_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Your attachment has been successfully saved.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
export const NOTES_DELETE_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Your note has been successfully deleted.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

// Player status update messages
export const STATUS_FAILURE_MESSAGE = {
  title: 'Error!',
  message: `Failed to change status, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const STATUS_SUCCESS_MESSAGE = {
  title: 'Success!',
  message: `Your status change has been successfully saved.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const E_KYC_SUCCESS = {
  title: 'Success!',
  message: `KYC check has been done successfully.`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export function E_KYC_FAIL(error) {
  return {
    title: 'Message',
    message: error,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }
}

export const SAVE_LIFETIME_DEPOSIT_LIMIT_SUCCESS = {
  title: 'Success!',
  message: `New affordability limit successfully saved`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const SAVE_LIFETIME_DEPOSIT_LIMIT_FAILURE = {
  title: 'Error!',
  message: `Failed to save affordability limit, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const SAVE_AFFORDABILITY_DEPOSIT_LIMIT_SUCCESS = {
  title: 'Success!',
  message: `New affordability deposit limit successfully saved`,
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}

export const SAVE_AFFORDABILITY_DEPOSIT_LIMIT_FAILURE = {
  title: 'Error!',
  message: `Failed to save affordability deposit limit, please try again or contact support.`,
  type: 'danger',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
}
