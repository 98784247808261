import {
  getPlayerBalances,
  getPlayerOverrideStatus,
  updatePlayerOverrideStatus,
} from '@services/queries'
import { useQuery, useMutation, useQueryClient } from 'react-query'

export function usePlayerBalances(playerId) {
  const info = useQuery(['player_balances', playerId], () =>
    getPlayerBalances(playerId),
  )

  return info
}

export function usePlayerOverrideStatus(playerId, enabled) {
  const info = useQuery(
    ['player_override_status', playerId],
    () => getPlayerOverrideStatus(playerId),
    { enabled },
  )

  return info
}

export function usePlayerOverrideStatusMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    ([id, data]) => {
      return updatePlayerOverrideStatus(id, data)
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['player_override_status'])

        if (onSuccess) onSuccess(data)
      },
      onError(err) {
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}
