import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAction } from '@redux/actions/sessionActions'
import { DOCUMENT_EVENTS, INACTIVITY_TIME } from '@constants'

export default function useAxiosClientAuth() {
  const dispatch = useDispatch()

  // Detect if user is inactive, if so logout
  useEffect(
    function inactivityTime() {
      let time

      function resetTimer() {
        clearTimeout(time)
        time = setTimeout(() => {
          dispatch(logoutAction())
        }, INACTIVITY_TIME)
      }

      // Activate event listeners for all activity events
      DOCUMENT_EVENTS.forEach((event) => {
        document.addEventListener(event, resetTimer, true)
      })

      return function cleanup() {
        DOCUMENT_EVENTS.forEach((event) => {
          document.removeEventListener(event, resetTimer, true)
        })
      }
    },
    [dispatch],
  )
}
