import {
  SAVE_LIFETIME_DEPOSIT_LIMIT_FAILURE,
  SAVE_LIFETIME_DEPOSIT_LIMIT_SUCCESS,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_FAILURE,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_SUCCESS,
} from '@constants'

import { store } from 'react-notifications-component'
import {
  getLifetimeDepositLimit,
  saveLifetimeDepositLimit,
  getAffordabilityDepositLimit,
  saveAffordabilityDepositLimit,
} from '@services/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export function useLifetimeDepositLimit(currencyCode) {
  const info = useQuery(['lifetime_deposit_limit', currencyCode], () =>
    getLifetimeDepositLimit(currencyCode),
  )

  return info
}

export function useNewLifetimeDepositLimit(onSuccess, onError) {
  const mutationInfo = useMutation(saveLifetimeDepositLimit, {
    onSuccess(data) {
      if (onSuccess) onSuccess(data)
      if (data) store.addNotification(SAVE_LIFETIME_DEPOSIT_LIMIT_SUCCESS)
    },
    onError(err) {
      store.addNotification(SAVE_LIFETIME_DEPOSIT_LIMIT_FAILURE)
      if (onError) onError(err)
    },
  })
  return mutationInfo
}

export function useAffordabilityDepositLimit(playerId, currencyCode) {
  const info = useQuery(
    ['affordability_deposit_limit', playerId, currencyCode],
    () => getAffordabilityDepositLimit(playerId, currencyCode),
  )

  return info
}

export function useNewAffordabilityDepositLimit(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(saveAffordabilityDepositLimit, {
    onSuccess(data) {
      if (onSuccess) onSuccess(data)
      queryClient.invalidateQueries(['affordability_deposit_limit'])
      store.addNotification(SAVE_AFFORDABILITY_DEPOSIT_LIMIT_SUCCESS)
    },
    onError(err) {
      store.addNotification(SAVE_AFFORDABILITY_DEPOSIT_LIMIT_FAILURE)
      if (onError) onError(err)
    },
  })
  return mutationInfo
}
