import { axiosClient } from '@services/clients'

export async function newTransactionLimit(dataT) {
  const data = await axiosClient.post('/transaction-limit', dataT)
  return data
}

export async function editTransactionLimit(id, dataT) {
  const data = await axiosClient.patch(`/transaction-limit/${id}`, dataT)
  return data
}

export async function deleteTransactionLimit(
  transactionLimitId,
  transactionLimitType,
) {
  const { data } = await axiosClient.delete(
    `/transaction-limit/${transactionLimitId}/${transactionLimitType}`,
  )
  return data
}

export async function getTransactionLimit(playerId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/transaction-limit/player/${playerId}`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
