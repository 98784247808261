export const countries = [
  {
    name: 'Afghanistan',
    value: 'AFG',
    dial_code: '+93',
  },
  {
    name: 'Aland Islands',
    value: 'ALA',
    dial_code: '+358',
  },
  {
    name: 'Albania',
    value: 'ALB',
    dial_code: '+355',
  },
  {
    name: 'Algeria',
    value: 'DZA',
    dial_code: '+213',
  },
  {
    name: 'American Samoa',
    value: 'ASM',
    dial_code: '+1684',
  },
  {
    name: 'Andorra',
    value: 'AND',
    dial_code: '+376',
  },
  {
    name: 'Angola',
    value: 'AGO',
    dial_code: '+244',
  },
  {
    name: 'Anguilla',
    value: 'AIA',
    dial_code: '+1264',
  },
  {
    name: 'Antarctica',
    value: 'ATA',
    dial_code: '+672',
  },
  {
    name: 'Antigua and Barbuda',
    value: 'ATG',
    dial_code: '+1268',
  },
  {
    name: 'Argentina',
    value: 'ARG',
    dial_code: '+54',
  },
  {
    name: 'Armenia',
    value: 'ARM',
    dial_code: '+374',
  },
  {
    name: 'Aruba',
    value: 'ABW',
    dial_code: '+297',
  },
  {
    name: 'Australia',
    value: 'AUS',
    dial_code: '+61',
  },
  {
    name: 'Austria',
    value: 'AUT',
    dial_code: '+43',
  },
  {
    name: 'Azerbaijan',
    value: 'AZE',
    dial_code: '+994',
  },
  {
    name: 'Bahamas',
    value: 'BHS',
    dial_code: '+1242',
  },
  {
    name: 'Bahrain',
    value: 'BHR',
    dial_code: '+973',
  },
  {
    name: 'Bangladesh',
    value: 'BGD',
    dial_code: '+880',
  },
  {
    name: 'Barbados',
    value: 'BRB',
    dial_code: '+1246',
  },
  {
    name: 'Belarus',
    value: 'BLR',
    dial_code: '+375',
  },
  {
    name: 'Belgium',
    value: 'BEL',
    dial_code: '+32',
  },
  {
    name: 'Belize',
    value: 'BLZ',
    dial_code: '+501',
  },
  {
    name: 'Benin',
    value: 'BEN',
    dial_code: '+229',
  },
  {
    name: 'Bermuda',
    value: 'BMU',
    dial_code: '+1441',
  },
  {
    name: 'Bhutan',
    value: 'BTN',
    dial_code: '+975',
  },
  {
    name: 'Bolivia, Plurinational State of',
    value: 'BOL',
    dial_code: '+591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
    dial_code: '+599',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BIH',
    dial_code: '+387',
  },
  {
    name: 'Botswana',
    value: 'BWA',
    dial_code: '+267',
  },
  {
    name: 'Bouvet Island',
    value: 'BVT',
    dial_code: '+47',
  },
  {
    name: 'Brazil',
    value: 'BRA',
    dial_code: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IOT',
    dial_code: '+246',
  },
  {
    name: 'Brunei Darussalam',
    value: 'BRN',
    dial_code: '+673',
  },
  {
    name: 'Bulgaria',
    value: 'BGR',
    dial_code: '+359',
  },
  {
    name: 'Burkina Faso',
    value: 'BFA',
    dial_code: '+226',
  },
  {
    name: 'Burundi',
    value: 'BDI',
    dial_code: '+257',
  },
  {
    name: 'Cambodia',
    value: 'KHM',
    dial_code: '+855',
  },
  {
    name: 'Cameroon',
    value: 'CMR',
    dial_code: '+237',
  },
  {
    name: 'Canada',
    value: 'CAN',
    dial_code: '+1',
  },
  {
    name: 'Cape Verde',
    value: 'CPV',
    dial_code: '+238',
  },
  {
    name: 'Cayman Islands',
    value: 'CYM',
    dial_code: '+ 345',
  },
  {
    name: 'Central African Republic',
    value: 'CAF',
    dial_code: '+236',
  },
  {
    name: 'Chad',
    value: 'TCD',
    dial_code: '+235',
  },
  {
    name: 'Chile',
    value: 'CHL',
    dial_code: '+56',
  },
  {
    name: 'China',
    value: 'CHN',
    dial_code: '+86',
  },
  {
    name: 'Christmas Island',
    value: 'CXR',
    dial_code: '+61',
  },
  {
    name: 'Cocos(Keeling) Islands',
    value: 'CCK',
    dial_code: '+61',
  },
  {
    name: 'Colombia',
    value: 'COL',
    dial_code: '+57',
  },
  {
    name: 'Comoros',
    value: 'COM',
    dial_code: '+269',
  },
  {
    name: 'Congo',
    value: 'COG',
    dial_code: '+242',
  },
  {
    name: 'Congo, the Democratic Republic of the',
    value: 'COD',
    dial_code: '+243',
  },
  {
    name: 'Cook Islands',
    value: 'COK',
    dial_code: '+682',
  },
  {
    name: 'Costa Rica',
    value: 'CRI',
    dial_code: '+506',
  },
  {
    name: "Côte d'Ivoire",
    value: 'CIV',
    dial_code: '+225',
  },
  {
    name: 'Croatia',
    value: 'HRV',
    dial_code: '+385',
  },
  {
    name: 'Cuba',
    value: 'CUB',
    dial_code: '+53',
  },
  {
    name: 'Curaçao',
    value: 'CUW',
    dial_code: '+599',
  },
  {
    name: 'Cyprus',
    value: 'CYP',
    dial_code: '+357',
  },
  {
    name: 'Czech Republic',
    value: 'CZE',
    dial_code: '+420',
  },
  {
    name: 'Denmark',
    value: 'DNK',
    dial_code: '+45',
  },
  {
    name: 'Djibouti',
    value: 'DJI',
    dial_code: '+253',
  },
  {
    name: 'Dominica',
    value: 'DMA',
    dial_code: '+1767',
  },
  {
    name: 'Dominican Republic',
    value: 'DOM',
    dial_code: '+1849',
  },
  {
    name: 'Ecuador',
    value: 'ECU',
    dial_code: '+593',
  },
  {
    name: 'Egypt',
    value: 'EGY',
    dial_code: '+20',
  },
  {
    name: 'El Salvador',
    value: 'SLV',
    dial_code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    value: 'GNQ',
    dial_code: '+240',
  },
  {
    name: 'Eritrea',
    value: 'ERI',
    dial_code: '+291',
  },
  {
    name: 'Estonia',
    value: 'EST',
    dial_code: '+372',
  },
  {
    name: 'Ethiopia',
    value: 'ETH',
    dial_code: '+251',
  },
  {
    name: 'Falkland Islands(Malvinas)',
    value: 'FLK',
    dial_code: '+500',
  },
  {
    name: 'Faroe Islands',
    value: 'FRO',
    dial_code: '+298',
  },
  {
    name: 'Fiji',
    value: 'FJI',
    dial_code: '+679',
  },
  {
    name: 'Finland',
    value: 'FIN',
    dial_code: '+358',
  },
  {
    name: 'France',
    value: 'FRA',
    dial_code: '+33',
  },
  {
    name: 'French Guiana',
    value: 'GUF',
    dial_code: '+594',
  },
  {
    name: 'French Polynesia',
    value: 'PYF',
    dial_code: '+689',
  },
  {
    name: 'French Southern Territories',
    value: 'ATF',
    dial_code: '+0',
  },
  {
    name: 'Gabon',
    value: 'GAB',
    dial_code: '+241',
  },
  {
    name: 'Gambia',
    value: 'GMB',
    dial_code: '+220',
  },
  {
    name: 'Georgia',
    value: 'GEO',
    dial_code: '+995',
  },
  {
    name: 'Germany',
    value: 'DEU',
    dial_code: '+49',
  },
  {
    name: 'Ghana',
    value: 'GHA',
    dial_code: '+233',
  },
  {
    name: 'Gibraltar',
    value: 'GIB',
    dial_code: '+350',
  },
  {
    name: 'Greece',
    value: 'GRC',
    dial_code: '+30',
  },
  {
    name: 'Greenland',
    value: 'GRL',
    dial_code: '+299',
  },
  {
    name: 'Grenada',
    value: 'GRD',
    dial_code: '+1473',
  },
  {
    name: 'Guadeloupe',
    value: 'GLP',
    dial_code: '+590',
  },
  {
    name: 'Guam',
    value: 'GUM',
    dial_code: '+1671',
  },
  {
    name: 'Guatemala',
    value: 'GTM',
    dial_code: '+502',
  },
  {
    name: 'Guernsey',
    value: 'GGY',
    dial_code: '+44',
  },
  {
    name: 'Guinea',
    value: 'GIN',
    dial_code: '+224',
  },
  {
    name: 'Guinea - Bissau',
    value: 'GNB',
    dial_code: '+245',
  },
  {
    name: 'Guyana',
    value: 'GUY',
    dial_code: '+595',
  },
  {
    name: 'Haiti',
    value: 'HTI',
    dial_code: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HMD',
    dial_code: '+61',
  },
  {
    name: 'Holy See(Vatican City State)',
    value: 'VAT',
    dial_code: '+379',
  },
  {
    name: 'Honduras',
    value: 'HND',
    dial_code: '+504',
  },
  {
    name: 'Hong Kong',
    value: 'HKG',
    dial_code: '+852',
  },
  {
    name: 'Hungary',
    value: 'HUN',
    dial_code: '+36',
  },
  {
    name: 'Iceland',
    value: 'ISL',
    dial_code: '+354',
  },
  {
    name: 'India',
    value: 'IND',
    dial_code: '+91',
  },
  {
    name: 'Indonesia',
    value: 'IDN',
    dial_code: '+62',
  },
  {
    name: 'Iran, Islamic Republic of',
    value: 'IRN',
    dial_code: '+98',
  },
  {
    name: 'Iraq',
    value: 'IRQ',
    dial_code: '+964',
  },
  {
    name: 'Ireland',
    value: 'IRL',
    dial_code: '+353',
  },
  {
    name: 'Isle of Man',
    value: 'IMN',
    dial_code: '+44',
  },
  {
    name: 'Israel',
    value: 'ISR',
    dial_code: '+972',
  },
  {
    name: 'Italy',
    value: 'ITA',
    dial_code: '+39',
  },
  {
    name: 'Jamaica',
    value: 'JAM',
    dial_code: '+1876',
  },
  {
    name: 'Japan',
    value: 'JPN',
    dial_code: '+81',
  },
  {
    name: 'Jersey',
    value: 'JEY',
    dial_code: '+44',
  },
  {
    name: 'Jordan',
    value: 'JOR',
    dial_code: '+962',
  },
  {
    name: 'Kazakhstan',
    value: 'KAZ',
    dial_code: '+77',
  },
  {
    name: 'Kenya',
    value: 'KEN',
    dial_code: '+254',
  },
  {
    name: 'Kiribati',
    value: 'KIR',
    dial_code: '+686',
  },
  {
    name: "Korea, Democratic People's Republic of",
    value: 'PRK',
    dial_code: '+850',
  },
  {
    name: 'Korea, Republic of',
    value: 'KOR',
    dial_code: '+82',
  },
  {
    name: 'Kuwait',
    value: 'KWT',
    dial_code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    value: 'KGZ',
    dial_code: '+996',
  },
  {
    name: "Lao People's Democratic Republic",
    value: 'LAO',
    dial_code: '+856',
  },
  {
    name: 'Latvia',
    value: 'LVA',
    dial_code: '+371',
  },
  {
    name: 'Lebanon',
    value: 'LBN',
    dial_code: '+961',
  },
  {
    name: 'Lesotho',
    value: 'LSO',
    dial_code: '+266',
  },
  {
    name: 'Liberia',
    value: 'LBR',
    dial_code: '+231',
  },
  {
    name: 'Libya',
    value: 'LBY',
    dial_code: '+218',
  },
  {
    name: 'Liechtenstein',
    value: 'LIE',
    dial_code: '+423',
  },
  {
    name: 'Lithuania',
    value: 'LTU',
    dial_code: '+370',
  },
  {
    name: 'Luxembourg',
    value: 'LUX',
    dial_code: '+352',
  },
  {
    name: 'Macao',
    value: 'MAC',
    dial_code: '+853',
  },
  {
    name: 'Macedonia, the former Yugoslav Republic of',
    value: 'MKD',
    dial_code: '+389',
  },
  {
    name: 'Madagascar',
    value: 'MDG',
    dial_code: '+261',
  },
  {
    name: 'Malawi',
    value: 'MWI',
    dial_code: '+265',
  },
  {
    name: 'Malaysia',
    value: 'MYS',
    dial_code: '+60',
  },
  {
    name: 'Maldives',
    value: 'MDV',
    dial_code: '+960',
  },
  {
    name: 'Mali',
    value: 'MLI',
    dial_code: '+223',
  },
  {
    name: 'Malta',
    value: 'MLT',
    dial_code: '+356',
  },
  {
    name: 'Marshall Islands',
    value: 'MHL',
    dial_code: '+692',
  },
  {
    name: 'Martinique',
    value: 'MTQ',
    dial_code: '+596',
  },
  {
    name: 'Mauritania',
    value: 'MRT',
    dial_code: '+222',
  },
  {
    name: 'Mauritius',
    value: 'MUS',
    dial_code: '+230',
  },
  {
    name: 'Mayotte',
    value: 'MYT',
    dial_code: '+262',
  },
  {
    name: 'Mexico',
    value: 'MEX',
    dial_code: '+52',
  },
  {
    name: 'Micronesia, Federated States of',
    value: 'FSM',
    dial_code: '+691',
  },
  {
    name: 'Moldova, Republic of',
    value: 'MDA',
    dial_code: '+373',
  },
  {
    name: 'Monaco',
    value: 'MCO',
    dial_code: '+377',
  },
  {
    name: 'Mongolia',
    value: 'MNG',
    dial_code: '+976',
  },
  {
    name: 'Montenegro',
    value: 'MNE',
    dial_code: '+382',
  },
  {
    name: 'Montserrat',
    value: 'MSR',
    dial_code: '+1664',
  },
  {
    name: 'Morocco',
    value: 'MAR',
    dial_code: '+212',
  },
  {
    name: 'Mozambique',
    value: 'MOZ',
    dial_code: '+258',
  },
  {
    name: 'Myanmar',
    value: 'MMR',
    dial_code: '+95',
  },
  {
    name: 'Namibia',
    value: 'NAM',
    dial_code: '+264',
  },
  {
    name: 'Nauru',
    value: 'NRU',
    dial_code: '+674',
  },
  {
    name: 'Nepal',
    value: 'NPL',
    dial_code: '+977',
  },
  {
    name: 'Netherlands',
    value: 'NLD',
    dial_code: '+31',
  },
  {
    name: 'New Caledonia',
    value: 'NCL',
    dial_code: '+687',
  },
  {
    name: 'New Zealand',
    value: 'NZL',
    dial_code: '+64',
  },
  {
    name: 'Nicaragua',
    value: 'NIC',
    dial_code: '+505',
  },
  {
    name: 'Niger',
    value: 'NER',
    dial_code: '+227',
  },
  {
    name: 'Nigeria',
    value: 'NGA',
    dial_code: '+234',
  },
  {
    name: 'Niue',
    value: 'NIU',
    dial_code: '+683',
  },
  {
    name: 'Norfolk Island',
    value: 'NFK',
    dial_code: '+672',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MNP',
    dial_code: '+1670',
  },
  {
    name: 'Norway',
    value: 'NOR',
    dial_code: '+47',
  },
  {
    name: 'Oman',
    value: 'OMN',
    dial_code: '+968',
  },
  {
    name: 'Pakistan',
    value: 'PAK',
    dial_code: '+92',
  },
  {
    name: 'Palau',
    value: 'PLW',
    dial_code: '+680',
  },
  {
    name: 'Palestinian Territory, Occupied',
    value: 'PSE',
    dial_code: '+970',
  },
  {
    name: 'Panama',
    value: 'PAN',
    dial_code: '+507',
  },
  {
    name: 'Papua New Guinea',
    value: 'PNG',
    dial_code: '+675',
  },
  {
    name: 'Paraguay',
    value: 'PRY',
    dial_code: '+595',
  },
  {
    name: 'Peru',
    value: 'PER',
    dial_code: '+51',
  },
  {
    name: 'Philippines',
    value: 'PHL',
    dial_code: '+63',
  },
  {
    name: 'Pitcairn',
    value: 'PCN',
    dial_code: '+872',
  },
  {
    name: 'Poland',
    value: 'POL',
    dial_code: '+48',
  },
  {
    name: 'Portugal',
    value: 'PRT',
    dial_code: '+351',
  },
  {
    name: 'Puerto Rico',
    value: 'PRI',
    dial_code: '+1939',
  },
  {
    name: 'Qatar',
    value: 'QAT',
    dial_code: '+974',
  },
  {
    name: 'Réunion',
    value: 'REU',
    dial_code: '+262',
  },
  {
    name: 'Romania',
    value: 'ROU',
    dial_code: '+40',
  },
  {
    name: 'Russian Federation',
    value: 'RUS',
    dial_code: '+7',
  },
  {
    name: 'Rwanda',
    value: 'RWA',
    dial_code: '+250',
  },
  {
    name: 'Saint Barthélemy',
    value: 'BLM',
    dial_code: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
    dial_code: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KNA',
    dial_code: '+1869',
  },
  {
    name: 'Saint Lucia',
    value: 'LCA',
    dial_code: '+1758',
  },
  {
    name: 'Saint Martin(French part)',
    value: 'MAF',
    dial_code: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'SPM',
    dial_code: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VCT',
    dial_code: '+1784',
  },
  {
    name: 'Samoa',
    value: 'WSM',
    dial_code: '+685',
  },
  {
    name: 'San Marino',
    value: 'SMR',
    dial_code: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'STP',
    dial_code: '+239',
  },
  {
    name: 'Saudi Arabia',
    value: 'SAU',
    dial_code: '+966',
  },
  {
    name: 'Senegal',
    value: 'SEN',
    dial_code: '+221',
  },
  {
    name: 'Serbia',
    value: 'SRB',
    dial_code: '+381',
  },
  {
    name: 'Seychelles',
    value: 'SYC',
    dial_code: '+248',
  },
  {
    name: 'Singapore',
    value: 'SGP',
    dial_code: '+65',
  },
  {
    name: 'Sint Maarten(Dutch part)',
    value: 'SXM',
    dial_code: '+599',
  },
  {
    name: 'Slovakia',
    value: 'SVK',
    dial_code: '+421',
  },
  {
    name: 'Slovenia',
    value: 'SVN',
    dial_code: '+386',
  },
  {
    name: 'Solomon Islands',
    value: 'SLB',
    dial_code: '+677',
  },
  {
    name: 'Somalia',
    value: 'SOM',
    dial_code: '+252',
  },
  {
    name: 'South Africa',
    value: 'ZAF',
    dial_code: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
    dial_code: '+500',
  },
  {
    name: 'South Sudan',
    value: 'SSD',
    dial_code: '+211',
  },
  {
    name: 'Spain',
    value: 'ESP',
    dial_code: '+34',
  },
  {
    name: 'Sri Lanka',
    value: 'LKA',
    dial_code: '+94',
  },
  {
    name: 'Sudan',
    value: 'SDN',
    dial_code: '+249',
  },
  {
    name: 'Suriname',
    value: 'SUR',
    dial_code: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'SJM',
    dial_code: '+47',
  },
  {
    name: 'Swaziland',
    value: 'SWZ',
    dial_code: '+268',
  },
  {
    name: 'Sweden',
    value: 'SWE',
    dial_code: '+46',
  },
  {
    name: 'Switzerland',
    value: 'CHE',
    dial_code: '+41',
  },
  {
    name: 'Taiwan, Province of China',
    value: 'TWN',
    dial_code: '+886',
  },
  {
    name: 'Tajikistan',
    value: 'TJK',
    dial_code: '+992',
  },
  {
    name: 'Tanzania, United Republic of',
    value: 'TZA',
    dial_code: '+255',
  },
  {
    name: 'Thailand',
    value: 'THA',
    dial_code: '+66',
  },
  {
    name: 'Timor - Leste',
    value: 'TLS',
    dial_code: '+670',
  },
  {
    name: 'Togo',
    value: 'TGO',
    dial_code: '+228',
  },
  {
    name: 'Tokelau',
    value: 'TKL',
    dial_code: '+690',
  },
  {
    name: 'Tonga',
    value: 'TON',
    dial_code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TTO',
    dial_code: '+1868',
  },
  {
    name: 'Tunisia',
    value: 'TUN',
    dial_code: '+216',
  },
  {
    name: 'Turkey',
    value: 'TUR',
    dial_code: '+90',
  },
  {
    name: 'Turkmenistan',
    value: 'TKM',
    dial_code: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TCA',
    dial_code: '+1649',
  },
  {
    name: 'Tuvalu',
    value: 'TUV',
    dial_code: '+688',
  },
  {
    name: 'Uganda',
    value: 'UGA',
    dial_code: '+256',
  },
  {
    name: 'Ukraine',
    value: 'UKR',
    dial_code: '+380',
  },
  {
    name: 'United Arab Emirates',
    value: 'ARE',
    dial_code: '+971',
  },
  {
    name: 'United Kingdom',
    value: 'GBR',
    dial_code: '+44',
  },
  {
    name: 'England',
    value: 'England',
    dial_code: '+44',
  },
  {
    name: 'USA',
    value: 'USA',
    dial_code: '+1',
  },
  {
    name: 'United States Minor Outlying Islands',
    value: 'UMI',
    dial_code: '+1',
  },
  {
    name: 'Uruguay',
    value: 'URY',
    dial_code: '+598',
  },
  {
    name: 'Uzbekistan',
    value: 'UZB',
    dial_code: '+998',
  },
  {
    name: 'Vanuatu',
    value: 'VUT',
    dial_code: '+678',
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    value: 'VEN',
    dial_code: '+58',
  },
  {
    name: 'Vietnam',
    value: 'VNM',
    dial_code: '+84',
  },
  {
    name: 'Virgin Islands, British',
    value: 'VGB',
    dial_code: '+1284',
  },
  {
    name: 'Virgin Islands, U.S.',
    value: 'VIR',
    dial_code: '+1340',
  },
  {
    name: 'Wallis and Futuna',
    value: 'WLF',
    dial_code: '+681',
  },
  {
    name: 'Western Sahara',
    value: 'ESH',
    dial_code: '+212',
  },
  {
    name: 'Yemen',
    value: 'YEM',
    dial_code: '+967',
  },
  {
    name: 'Zambia',
    value: 'ZMB',
    dial_code: '+260',
  },
  {
    name: 'Zimbabwe',
    value: 'ZWE',
    dial_code: '+263',
  },
]

export default countries
