import React from 'react'
import PropTypes from 'prop-types'
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import Card from '@components/molecules/Cards/Card'

function DemographicsDropdown({ label }) {
  return (
    <Card title={label}>
      <CDropdown selected="Statistics" className="c-dropdown-button">
        <CDropdownToggle className="player-c">Demographics</CDropdownToggle>
        <CDropdownMenu className="player-c dropdown-generic">
          <CDropdownItem className="c-player-card-text">
            Demographics
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </Card>
  )
}

DemographicsDropdown.propTypes = {
  label: PropTypes.string,
}

DemographicsDropdown.defaultProps = {
  label: '',
}

export default DemographicsDropdown
