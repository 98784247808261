import React from 'react'
import { CSelect } from '@coreui/react'
import PropTypes from 'prop-types'

function GenericDropdown({ label, onChange, innerRef, options, selected }) {
  return (
    <>
      <label className="text-xs text-gray-500 mt-2" htmlFor="wallet">
        {label}
      </label>
      <CSelect
        id="Wallet"
        name="Wallet"
        innerRef={innerRef()}
        value={selected}
        onChange={onChange}
        className="player-c"
      >
        {options?.map(({ value, label: _label }) => (
          <option key={value} value={value}>
            {_label}
          </option>
        ))}
      </CSelect>
    </>
  )
}

GenericDropdown.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.any,
}

GenericDropdown.defaultProps = {
  innerRef: { current: null },
  label: '',
  onChange: () => null,
  options: [],
  selected: false,
}

export default GenericDropdown
