// Default colors of the application
export const baseTheme = {
  primary: '#1D345F',
  secondary: '#D5E3F1',
  third: '#939393',
  fourth: '#569ADF',
  fifth: '#D5E3F1',
  sixth: '#2E464D',
  seventh: '#ffffff',
  eighth: '#18AC79',
  nine: '#c75555',
  tenth: '#0E1A2F',
}

export const mapTheme = (variables) => {
  return {
    '--color-primary': variables.primary || baseTheme.primary,
    '--color-secondary': variables.secondary || baseTheme.secondary,
    '--color-third': variables.third || baseTheme.third,
    '--color-fourth': variables.fourth || baseTheme.fourth,
    '--color-fifth': variables.fifth || baseTheme.fifth,
    '--color-sixth': variables.sixth || baseTheme.sixth,
    '--color-seventh': variables.seventh || baseTheme.seventh,
    '--color-eighth': variables.eighth || baseTheme.eighth,
    '--color-nine': variables.nine || baseTheme.nine,
    '--color-tenth': variables.tenth || baseTheme.tenth,
  }
}

export const applyTheme = (theme) => {
  const themeObject = mapTheme(theme)
  if (!themeObject) return

  const root = document.documentElement

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return
    }

    root.style.setProperty(property, themeObject[property])
  })
}
